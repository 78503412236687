<template>

  <div class="steps"> 
    <template
      v-for="item in steps"
      >
      <div :class="'step ' + states[item.id]" @click="clickStep(item.id, item.el)">

        <div class="ste">
          <i class="ic bad icon-smile-bad"></i>
          <i class="ic normal icon-smile-medium"></i>
          <i class="ic good icon-smile-good"></i>

          <div class="cont">
            <div class="ttl">
              {{ item.name }}
            </div>
            <div class="hint">
              {{ states[item.id] == 'bad' ? item.hintBad : '' }}
              {{ states[item.id] == 'normal' ? item.hintNormal : '' }}
              {{ states[item.id] == 'good' ? item.hintGood : '' }}
            </div>
          </div>
        </div>

      </div>
    </template>
  </div>
  
</template>


<script setup>

const emits = defineEmits(['click-step'])

const props = defineProps({
  steps: {
    type: Array,
    default: () => [],
  },
  states: {
    type: Object,
    default: () => ({}),
  },
})



const clickStep = function(id, el){
  scrollStep(el.value ? el.value : el)
  emits('click-step', id)
}

const scrollStep = function(el){
   
  if(el && el.getBoundingClientRect){
    
    const elementTop = el.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: elementTop - 120, 
      behavior: 'smooth'
    });
  }
}


</script>
 


<style scoped>
   

.steps{
    /* position: relative; */
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background: 0 0;
    box-shadow: none;
    width: 100%;
    color: white;
    position: sticky;
    top: 50px;
    z-index: 10;


  container: steps / inline-size;
    

}
.steps .step:first-child{
    border-radius: 4px 0px 0px 4px;

}
.steps .step:last-child{
    border-radius: 0px  4px 4px 0px ;

}
.steps .step{
  flex: 1 1 0px;
  container: step / inline-size;
  background: #FFF;
  display: flex;
}
.steps .step .ste{
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-start;
  
  color: rgba(0,0,0,.87);
  box-shadow: none;
  border-radius: 0;
  border: none;
  transition: all 0.5s ease-in-out;
  width: 100%;
  gap: 0.4rem 0.7rem;
  margin: 1.1rem;
  
}

.steps .step:not(:first-child){
  border-left: 1px var(--un-background-color) solid;
}

.steps .step .ic{
  color: white;
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: middle;
  -ms-flex-item-align: middle;
  align-self: middle;
  line-height: 1;
  font-size: 2.5em;
}

.steps .step.bad .ic.normal{ display: none; }
.steps .step.bad .ic.good{ display: none; }

.steps .step.normal .ic.bad{ display: none; }
.steps .step.normal .ic.good{ display: none; }

.steps .step.good .ic.bad{ display: none; }
.steps .step.good .ic.normal{ display: none; }

 
.steps .step .ttl{
    font-size: 1.14285714em;
    font-weight: 700;
    color: white;
}
.steps .step .hint{
  font-weight: 400;
  font-size: .92857143em;
  color: rgba(255, 255, 255, 0.87);
  /*
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
}
.steps .step.good{
  background-color: #86d280;
}
.steps .step.good .smile{
  display: inline-block;
}
.steps .step.good .meh,
.steps .step.good .frown{
  display: none;
}

.steps .step.normal{
  background-color: #e4d624;
}
.steps .step.normal .meh{
  display: inline-block;
}

.steps .step.normal .smile,
.steps .step.normal .frown{
  display: none;
}

.steps .step.bad{
  background-color: #e66d33;
}
.steps .step.bad .frown{

}
.steps .step.bad .smile,
.steps .step.bad .meh{
  display: none;
}

.steps.fixed{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.36);
}



.steps.week_progress .step{
  width: 25%;
}

.steps.harvest_progress .step{
  width: 15%;
}
.steps .step{

}

.steps .step .cont{
  width: 100%;
}
.steps .step.step_star{
  width: 50px!important;
  max-width: 50px;
  background-color: #f9f9f9;
}

.steps .step.step_star .hint{
  font-size: 0.75rem;
}
.steps .step.step_star .name,
.steps .step.step_star .hint,
.steps .step.step_star .icon{
  color: #fbbd08;
}
.steps .step.step_star .content{
  width: 100%;
  margin: 0px;
  text-align: center;
}
.steps .step.step_star .ic{
  flex-shrink: 0;
}
.steps .step.step_star .icon{
  display: block;
  width: 100%;
  margin: 0px;
  margin-top: 7px;
  font-size: 2rem;
  margin-bottom: 7px;
}

.steps.active .step .icon,
.steps.active .step .hint{
  display: none;
}

.avatar_edit{
  width: 100px;
  float: right;
}


 

@container step (max-width: 200px){
  .steps .step .ste{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0.3rem 0.7rem;
  }
  .steps .step .ste .ic{
    font-size: 1.5rem;
  }
}
 

@container step (max-width: 170px){
  .steps .step .ste{
    margin: 0.4rem;
    gap: 0.1rem;
  }
  .steps .step .ste .ttl{
    font-size: 0.942857em;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .steps .step .ste .hint{
    display: none;
  }
}

@container step (max-width: 120px){
  /* .steps .step .ste{
    margin: 0.4rem;
    gap: 0.1rem;
  } */
  .steps .step .ste .ic{
    display: none;
  }
}

</style>